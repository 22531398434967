
@media only screen and (max-width: 1279px) {

}

@media only screen and (max-width: 991px) {
  h1 {
    margin-bottom: 32px;
  }
  h4 {
    margin-bottom: 32px;
  }
  main {
    padding: 0 24px;
  }
  footer, header {
    padding: 24px;
  }

  .bg img {
    width: 1000px;
    left: auto;
    top: auto;
  }
  .btn-block-text.first {
    display: flex;
  }
  .btn-block-text.second {
    display: none;
  }
  .btn-earn {
    position: sticky;
    top: 16px;
    left: 50%;

    transform: translateX(-50%);
  }
  .btn-block br {
    display: none;
  }
  .btn-block-text {
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    margin-bottom: 24px;
    align-items: flex-end;
  }
  .btn-block-text > div {
    max-width: 300px;
  }
  .btn-block-text .react-icon {
    display: flex;
    justify-content: flex-start;
    position: relative;
    height: 50px;
    bottom: -20px;
    right: 0px;
  }
  .btn-block-text .react-icon div {
    transform: scale(-1, 1) rotate(-45deg);
  }

  .btn-block .btn {
    align-self: center;

  }
  .items {
    flex-direction: column;
    margin: 0;
    margin-top: 24px;
    padding-bottom: 8px;
    align-items: center;
  }
  .item {
    width: 100%;
    margin: 0;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
  .btn-earn {
    max-width: 100%;
    left: auto;
    transform: translateX(0);
    width: 100%;
  }
  .item {
    width: 100%;
    max-width: 100%;

  }
  .item br{
     display: none;
  }
  .btn-block-text {
    max-width: 85%;
  }
  .btn-block-text br {
    display: none;
  }
  header {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .header-logo {
    font-size: 32px;
    margin-bottom: 24px;
  }
}

