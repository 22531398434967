header {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 80px;
  overflow: hidden;
}

header img {
  width: 224px
}
.header-bg {
  display: none;
}


.header-lang-select {
  margin-right: 16px;
  border-radius: 24px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font-medium);
  color: var(--main-bg-color);
  cursor: pointer;
  transition: var(--transition);
  border: 1px solid #fff;
  min-width: 78px;
  height: 48px;
  background-color: transparent;
  display: flex;
}

.header-lang-select-value {
  flex: 1;
  position: relative;
  border-radius: 24px;
  transition: var(--transition);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
  padding: 0 12px 0 24px;
}

.header-lang-select:hover, .header-lang-select.visible {
  background-color: #424242;
}


.header-lang-select-hidden {
  position: absolute;
  top: 20px;
  left: -1px;
  right: -1px;
  padding-top: 18px;
  color: rgba(255, 255, 255, 0.3);
  background: #424242;
  border-radius: 0 0 24px 24px;
  display: none;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-top: none;
}


.header-lang-select.visible .header-lang-select-hidden {
  display: block;
}


.header-lang-select-hidden-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: var(--transition);
  width: 100%;
  padding: 8px 8px 8px 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.header-lang-select-hidden-item:hover {
  color: var(--tint-color);
}
