::-webkit-scrollbar, iframe body::-webkit-scrollbar, .body-inside-iframe::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
  background-color: transparent !important;
  /*border: 1px solid #d5d5d5 !important;*/
}

::-webkit-scrollbar-thumb, iframe body::-webkit-scrollbar-thumb, .body-inside-iframe::-webkit-scrollbar-thumb {
  background-color: var(--border-color) !important;
  border: 2px solid var(--border-color) !important;
}

html {
  scroll-behavior: smooth;
  /*overflow: hidden;*/
  overflow-x: hidden;
}

body {
  position: relative;
  width: 100vw;
  color: var(--text-color);
  font-family: var(--font-refular);
  font-size: 18px;
  line-height: 24px;
  background: url("../images/bg.png") no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
  background-color: var(--main-bg-color);
  margin: 0;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
}

main {
  padding: 0 80px 32px;
  flex: 1;
  position: relative;
  width: 100vw;
  overflow: hidden;
}


h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
}

h1 {
  text-wrap: balance;
  font-size: clamp(24px, 4vw, 56px);
  line-height: clamp(28px, 4vw, 64px);
  margin-bottom: 40px;
  font-family: var(--font-bold);
}

h2 {
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 60px;
  font-family: var(--font-bold);
}

h3 {
  font-size: 24px;
  line-height: 28px;
  font-family: var(--font-bold);
}

h4 {
  font-family: var(--font-refular);
  font-size: 18px;
  margin-top: -16px;
  margin-bottom: 40px;
}

.btn {
  height: 48px;
  border-radius: 24px;
  transition: var(--transition);
  font-family: var(--font-medium);
  position: relative;
  font-size: 16px;
  line-height: 22px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  width: auto;
}

.btn:active {
  box-shadow: none;
}

.btn-lg {
  height: 64px;
  font-size: 18px;
  line-height: 28px;
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
  border-radius: 80px;
}

.btn-primary {
  box-shadow: none;
  background-color: var(--tint-color);
  border: none;
  color: var(--main-bg-color);
}

.btn-primary:focus {
  box-shadow: none;
  background-color: var(--tint-color);
  color: var(--main-bg-color);
}

.btn-primary:active:focus {
  border-color: #DCAC03;
  background-color: #DCAC03;
  box-shadow: none;
}


.btn-primary:hover {
  background-color: var(--tint-color);
  color: var(--main-bg-color);
  box-shadow: 0px 0px 20px rgba(255, 199, 0, 0.8);
}

.btn.btn-primary.disabled {
  pointer-events: none;
  background-color: var(--main-bg-color);
  color: var(--secondary-bg-color);
}

.text-tint {
  color: var(--tint-color)
}

.btn-earn {
  min-width: 250px;
  padding-right: 80px;
  display: inline-flex !important;
}

.btn-earn:after {
  position: absolute;
  content: '';
  width: 56px;
  height: 56px;
  right: 4px;
}

.items {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 52px -12px -12px;
}

.item {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  background: rgba(20, 20, 20, 0.20);
  backdrop-filter: blur(5px);
  padding: 24px;
  width: 20%;
  margin: 12px;
  min-width: 250px;
  max-width: 380px;
}
.item img {
  margin-bottom: 16px;
  width: 32px;
  min-width: 32px;
  height: 32px;
}
.item:before {
  content: '';
  position: absolute;
  background-color: rgba(255, 199, 0, 0.20);
  filter: blur(8px);
  width: 52px;
  height: 52px;
  left: 8px;
  top: 8px;
  border-radius: 50%;
  z-index: -1;
}

.item span {
  font-family: var(--font-bold);
}

.bg {
  position: absolute;
  bottom: -400px;
  right: -200px;
  top: 0;
  left: 0;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: -1;
}

.bg:before {
  content: '';
  position: absolute;
  width: 766px;
  height: 766px;
  border-radius: 766px;
  opacity: 0.1;
  background: #E11E1E;
  filter: blur(110px);
  left: -300px;
  bottom: -300px;
  pointer-events: none;
  z-index: 1;
}

.bg img {
  width: 500px;
  position: relative;
  right: -500px;
  top: 130px;
}

.btn-block-text {
  text-wrap: balance;
  padding-left: 24px;
  display: inline-flex;
  flex-direction: column;

}

.btn-block-text.first {
  display: none;
}

.react-icon > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-secondary {
  background: #262626;
}

.btn.btn-secondary:hover {
  background: #3E3A38 !important;
}

.btn.btn-secondary:active {
  background: #3E3A38 !important;
}

.legal-btn-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;
}

.legal-btn-list a {
  width: calc(25% - 12px);
}
a {
  color: var(--tint-color);
  transform: var(--transition);
  &:hover {
    color: var(--tint-color);
    text-shadow: 0px 0px 20px rgba(255, 199, 0, 0.8);
  }
}
footer {
  background: rgba(20, 20, 20, 0.20);
  backdrop-filter: blur(5px);
  padding: 24px 80px;
}
header {
  margin-bottom: 48px;
}

.header-logo {
  transition: var(--transition);
  font-size: 48px;
  text-decoration: none!important;
  white-space: nowrap;
}

.cookies {
  position: absolute;
  right: -305px;
  bottom: 24px;
  background: var(--secondary-bg-color);
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  padding: 24px;
  width: 300px;
  transition: all 0.6s ease;
}
.cookies.visible {
  right: 24px;
}