:root {
  --main-bg-color: #141414;
  --secondary-bg-color: #646464;
  --text-color: #ffffff;
  --tint-color: #FFC700;
  --muted-color: rgba(255,255,255,0.5);
  --divider: rgba(255,255,255,0.5);
  --border-color: rgba(255,255,255,0.5);

  --danger: #E11E1E;


  --font-refular: 'NTSomic-Regular', sans-serif;
  --font-medium: 'NTSomic-Medium', sans-serif;
  --font-bold: 'NTSomic-Bold', sans-serif;

  --transition: all .3s ease;
  --transition-1s: all 1s ease;
}
