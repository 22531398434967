.circle-orion {
  position: absolute;
  border: 1px solid rgba(255, 199, 0, .5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*.circle-orion:after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  left: -10px;*/
/*  right: -10px;*/
/*  bottom: -10px;*/
/*  height: 90%;*/
/*  background: linear-gradient(to top, var(--main-bg-color) 60%, transparent 80%);*/
/*}*/

.circle-orion:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: var(--tint-color);
  border-radius: 16px;
}

.circle-orion.sm {
  width: 386px;
  height: 386px;
  margin-top: 480px;
  margin-left: 0px;
}

.circle-orion.sm:before {
  animation: spin1 20s infinite linear;
}

@keyframes spin1 {
  from {
    transform: rotate(0.5turn) translateY(-197px) translateY(50%) rotate(1.5turn);
  }
  to {
    transform: rotate(1.5turn) translateY(-197px) translateY(50%) rotate(0.5turn);
  }
}

.circle-orion.lg {
  width: 586px;
  height: 586px;
  margin-top: 330px;
  margin-left: 540px;
}

.circle-orion.lg:before {
  animation: spin2 20s infinite linear;
}

@keyframes spin2 {
  from {
    transform: rotate(0turn) translateY(-297px) translateY(50%) rotate(1turn);
  }
  to {
    transform: rotate(1turn) translateY(-297px) translateY(50%) rotate(0turn);
  }
}

.circle-orion.xl {
  width: 770px;
  height: 770px;
  margin-top: 280px;
  margin-right: -1200px;
}

.circle-orion.xl:before {
  animation: spin3 20s infinite linear;
}

@keyframes spin3 {
  from {
    transform: rotate(0.7turn) translateY(-389px) translateY(50%) rotate(1.7turn);
  }
  to {
    transform: rotate(1.7turn) translateY(-389px) translateY(50%) rotate(0.7turn);
  }
}

.circle-orion-bg {
  position: absolute;
  opacity: 0.1;
  filter: blur(110px);
  border-radius: 50%;
  width: 50%;
  height: 50%;
  z-index: 1;
}

.circle-orion-bg.red {
  background: var(--danger);
  margin-top: -300px;
  margin-left: -500px;
}

.circle-orion-bg.yellow {
  background: var(--tint-color);
}


@media only screen and (max-width: 991px) {
  .circle-orion.sm {
    width: 184px;
    height: 184px;
    margin-top: 100px;
    margin-left: -150px;
  }

  @keyframes spin1 {
    from {
      transform: rotate(0.5turn) translateY(-96px) translateY(50%) rotate(1.5turn);
    }
    to {
      transform: rotate(1.5turn) translateY(-96px) translateY(50%) rotate(0.5turn);
    }
  }
  .circle-orion.lg {
    width: 280px;
    height: 280px;
    margin-top: 120px;
    margin-left: 140px;
  }

  @keyframes spin2 {
    from {
      transform: rotate(0turn) translateY(-144px) translateY(50%) rotate(1turn);
    }
    to {
      transform: rotate(1turn) translateY(-144px) translateY(50%) rotate(0turn);
    }
  }
  .circle-orion.xl {
    width: 370px;
    height: 370px;
    margin-top: 120px;
    margin-right: -500px;
  }

  @keyframes spin3 {
    from {
      transform: rotate(0.7turn) translateY(-189px) translateY(50%) rotate(1.7turn);
    }
    to {
      transform: rotate(1.7turn) translateY(-189px) translateY(50%) rotate(0.7turn);
    }
  }
}